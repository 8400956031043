.price {
  display: flex;
  align-items: center;
  gap: 10px;
  @media (max-width: 720px) {
    gap: 8px;
  }
}

.regularPrice {
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;

  @media (max-width: 720px) {
    font-size: 20px;

    &.largePrice {
      font-size: 24px;
    }
  }
}

.priceItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.priceValue {
  color: #333;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  @media (max-width: 720px) {
    font-size: 16px;
    line-height: 16px;

    &.largePrice {
      font-size: 24px;
      line-height: 29px;
    }
  }

  &.mediumPrice {
    font-size: 20px;
    line-height: 24px;
  }

  &.discountValue {
    color: #ff5c32;
  }
}

.deliveryDate {
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  color: #999;
  display: flex;
  align-items: center;
  gap: 4px;
  @media (max-width: 720px) {
    gap: 2px;
    &.smallDeliveryInfo {
      font-size: 10px;
      line-height: 14px;
    }
  }

  &.mediumDeliveryInfo {
    font-size: 12px;
    line-height: 17px;
  }
}

.icon {
  & > svg > g {
    stroke: #999;
  }
}

.priceWithoutDiscount {
  text-decoration: line-through;
  color: #989898;
  font-size: 16px;
  line-height: 120%;
  font-weight: 500;
  margin-right: 12px;

  @media (max-width: 720px) {
    font-size: 12px;
    margin-right: 6px;

    &.largePrice {
      font-size: 16px;
    }
  }
}

.priceWithDiscount {
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  color: #ff5c32;

  @media (max-width: 720px) {
    font-size: 20px;

    &.largePrice {
      font-size: 24px;
    }
  }
}
